// import logo from './logo.svg';
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoadingSpinner from "./components/UI/LoadingSpinner";
// import Button from '@material-ui/core/Button';

import './App.css';
const NewSwimmer = lazy(() => import("./pages/NewSwimmer"));
// const SwimmerDetail = lazy(() => import("./pages/SwimmerDetail"));
// const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
  return (
    <div className="App">
      <header className="App-header">
         <Suspense fallback={ <p className="centered"> <LoadingSpinner /> </p> }
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<NewSwimmer />} />
              <Route path="/swimmer" exact element={<NewSwimmer />} />
              {/* <Route path="/swimmer-detail" element={<SwimmerDetail />} /> */}
           
            </Routes>
          </BrowserRouter>
        </Suspense>
      </header>
    </div>
  );
}

export default App;
